import { AccountCircle, PersonAddAlt1 } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import Avatar from "common/components/Avatar";
import Typography from "common/components/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { RoomPlayer } from "types/Room/type";

type SelectPlayerType = {
  player?: RoomPlayer;
  color: "player1" | "player2";
  onClick: () => void;
};

const SelectPlayer = (props: SelectPlayerType) => {
  const { t } = useTranslation();
  const { player, color, onClick } = props;

  return (
    <Stack
      alignItems="center"
      direction={color === "player1" ? "row" : "row-reverse"}
      width="100%"
      gap={2}
      sx={{
        background: player
          ? ({ palette }) =>
              `linear-gradient(to ${color === "player1" ? "right" : "left"}, ${
                palette.common[color].text
              } 1%, ${palette.common[color].bgConstel} 1% 75%, transparent)`
          : "transparent",
        height: "fit-content",
        padding: 2,
      }}
    >
      <Avatar character={player?.avatar} />
      {player ? (
        <Stack direction="column" width="100%">
          <Typography
            textAlign="center"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            width="100%"
          >
            {player?.pseudo ?? ""}
          </Typography>
          <Typography
            size="sm"
            textAlign="center"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            width="100%"
          >
            {t("Score de box : {{count}}", {
              count: player.score,
            })}
          </Typography>
        </Stack>
      ) : (
        <>
          <Typography size="sm" color="gray">
            {t("En attente de joueurs...")}
          </Typography>
          <IconButton
            sx={{ color: ({ palette }) => palette.common[color].text }}
            onClick={onClick}
          >
            <PersonAddAlt1 />
          </IconButton>
        </>
      )}
    </Stack>
  );
};

export default SelectPlayer;
