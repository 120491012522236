import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Tooltip,
  styled,
} from "@mui/material";
import Typography from "common/components/Typography";
import React, { useContext, useEffect, useState } from "react";
import { useDebouncedState } from "common/hooks/useDebounce";
import { useTranslation } from "react-i18next";
import GenshinButton from "common/components/GenshinButton";
import { DividerLeft, DividerRight, Icon4Star, Icon5Star } from "assets/icons";
import { useErrorNotification } from "common/components/Notification";
import { RoomContext } from "App/context";
import { WithGrow } from "common/components/WithGrow";
import Paper from "common/components/Paper";
import PlayerCard from "./PlayerCard";
import { getPlayers } from "service/player";
import { Player } from "service/player/type";
import ComparePlayerCard from "./ComparePlayerCard";
import { CHARACTERS_LIST } from "types/Character";
import RowCharacter from "./RowCharacter";
import { DraftParams } from "service/draft/type";
import { getDraftParams } from "service/draft";
import BanButton from "./BanButton";
import { Backspace, ExpandMore } from "@mui/icons-material";

type PlayersBoxProps = {};

const PlayersBox = (props: PlayersBoxProps) => {
  const { dispatch, localPlayer } = useContext(RoomContext);
  const dispatchError = useErrorNotification();
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [debouncedValue, setDebouncedValue] = useDebouncedState<string>(
    search,
    (value) => setSearch(value)
  );
  const [playersList, setPlayersList] = useState<Player[]>([]);
  const [player1, setPlayer1] = useState<Player | undefined>(localPlayer);
  const [player2, setPlayer2] = useState<Player | undefined>();
  const [draftParams, setDraftParams] = useState<DraftParams | undefined>();

  useEffect(() => {
    const fetch = async () => {
      try {
        setPlayersList(await getPlayers());
      } catch (e) {
        dispatchError(e);
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    const checkDraftParams = async () => {
      const boxPlayer1 = player1?.boxes?.at(0);
      const boxPlayer2 = player2?.boxes?.at(0);
      if (
        boxPlayer1?.score !== undefined &&
        boxPlayer2?.score !== undefined &&
        boxPlayer1?.characters !== undefined &&
        boxPlayer2?.characters !== undefined
      ) {
        setDraftParams(
          await getDraftParams(
            boxPlayer1.score,
            boxPlayer2.score,
            boxPlayer1.characters,
            boxPlayer2.characters
          )
        );
      }
    };
    if (player1 !== undefined && player2 !== undefined) checkDraftParams();
    else setDraftParams(undefined);
  }, [player1, player2]);

  return (
    <WithGrow
      direction="column"
      sx={{
        gap: 2,
      }}
      fixedSx={{
        width: "100%",
        alignItems: "center",
      }}
      fixed={
        <React.Fragment>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={{
              svg: { width: "15rem" },
            }}
          >
            <DividerRight />
            <Typography>{t("Box des joueurs")}</Typography>
            <DividerLeft />
          </Stack>
          <Stack width="100%" direction="row" justifyContent="start" gap={1}>
            <FormControl variant="outlined" size="small">
              <InputLabel>{t("Chercher")}</InputLabel>
              <OutlinedInput
                value={debouncedValue}
                onChange={(event) => setDebouncedValue(event.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setDebouncedValue("")}>
                      <Backspace />
                    </IconButton>
                  </InputAdornment>
                }
                label={t("Chercher")}
              />
            </FormControl>
            {/* <GenshinButton>{t("Comparer")}</GenshinButton> */}
          </Stack>
        </React.Fragment>
      }
    >
      <Stack direction="row" height="100%" gap={2}>
        <Stack height={0} minHeight="100%" flex={1}>
          <Paper
            sx={{
              backgroundColor: "unset",
              width: "100%",
              display: "grid",
              gridTemplateColumns:
                playersList.length > 0
                  ? "repeat(auto-fill, minmax(130px, 1fr))"
                  : "",
              gap: (theme) => theme.spacing(2),
              overflow: "auto",
              height: "100%",
            }}
          >
            {playersList.length === 0 ? (
              <CircularProgress
                sx={{
                  position: "relative",
                  top: "50%",
                  left: "50%",
                }}
              />
            ) : (
              playersList
                .filter(
                  (player) =>
                    (player.boxes?.length ?? 0) > 0 &&
                    player.pseudo?.toLowerCase().includes(search.toLowerCase())
                )
                .sort(
                  (playerA, playerB) =>
                    (playerA.pseudo ?? "").localeCompare(playerB.pseudo ?? "")
                  // (playerA.boxes?.at(0)?.score ?? 0) >
                  // (playerB.boxes?.at(0)?.score ?? 0)
                  //   ? -1
                  //   : 1
                )
                .map((player, index) => (
                  <PlayerCard
                    key={index}
                    player={player}
                    selected={
                      JSON.stringify(player1) === JSON.stringify(player) ||
                      JSON.stringify(player2) === JSON.stringify(player)
                    }
                    onClick={() => {
                      if (JSON.stringify(player1) === JSON.stringify(player))
                        setPlayer1(undefined);
                      else if (
                        JSON.stringify(player2) === JSON.stringify(player)
                      )
                        setPlayer2(undefined);
                      else {
                        player1 === undefined
                          ? setPlayer1(player)
                          : setPlayer2(player);
                      }
                    }}
                  />
                ))
            )}
          </Paper>
        </Stack>
        <Stack flex={1} height={0} minHeight="100%">
          <Paper sx={{ height: "100%" }}>
            <Stack direction="column" height="100%" gap={2}>
              <Stack direction="row" justifyContent="space-evenly">
                <Stack direction="row" justifyContent="center" gap={3}>
                  <ComparePlayerCard
                    player={player1}
                    onDelete={() => setPlayer1(undefined)}
                    color="player1"
                  />
                  <ComparePlayerCard
                    player={player2}
                    onDelete={() => setPlayer2(undefined)}
                    color="player2"
                  />
                </Stack>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  gap={1}
                >
                  <BanButton
                    badgeQuantity={draftParams?.jokerBans}
                    whoBan={
                      (draftParams?.jokerBans ?? 0) < 0 ? "player1" : "player2"
                    }
                    label={t("Bans joker")}
                  />
                  <BanButton
                    badgeQuantity={draftParams?.balancingBans}
                    whoBan={
                      (draftParams?.balancingBans ?? 0) < 0
                        ? "player1"
                        : "player2"
                    }
                    label={t("Bans d'équilibrage")}
                  />
                </Stack>
              </Stack>
              <Stack overflow="auto">
                {player1 && player2 && (
                  <>
                    <Accordion
                      sx={{
                        bgcolor: ({ palette }) => palette.background.highlight,
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Box
                          display="flex"
                          sx={{ svg: { width: "3.5rem", height: "3.5rem" } }}
                        >
                          <Icon5Star />
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack>
                          <Divider orientation="horizontal" />
                          {CHARACTERS_LIST.filter((c) => c.rarity === "5").map(
                            (character, index) => (
                              <React.Fragment key={index}>
                                <RowCharacter
                                  character={character}
                                  constellationPlayer1={
                                    player1?.boxes
                                      ?.at(0)
                                      ?.characters.find(
                                        (c) => c.name === character.name
                                      )?.constellation
                                  }
                                  constellationPlayer2={
                                    player2?.boxes
                                      ?.at(0)
                                      ?.characters.find(
                                        (c) => c.name === character.name
                                      )?.constellation
                                  }
                                />
                                <Divider orientation="horizontal" />
                              </React.Fragment>
                            )
                          )}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      sx={{
                        bgcolor: ({ palette }) => palette.background.highlight,
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Box
                          display="flex"
                          sx={{ svg: { width: "3.5rem", height: "3.5rem" } }}
                        >
                          <Icon4Star />
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack>
                          <Divider orientation="horizontal" />
                          {CHARACTERS_LIST.filter((c) => c.rarity === "4").map(
                            (character, index) => (
                              <React.Fragment key={index}>
                                <RowCharacter
                                  character={character}
                                  constellationPlayer1={
                                    player1?.boxes
                                      ?.at(0)
                                      ?.characters.find(
                                        (c) => c.name === character.name
                                      )?.constellation
                                  }
                                  constellationPlayer2={
                                    player2?.boxes
                                      ?.at(0)
                                      ?.characters.find(
                                        (c) => c.name === character.name
                                      )?.constellation
                                  }
                                />
                                <Divider orientation="horizontal" />
                              </React.Fragment>
                            )
                          )}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </Stack>
    </WithGrow>
  );
};

export default PlayersBox;
