import { CardMedia, Divider, Stack } from "@mui/material";
import Typography from "common/components/Typography";
import React, { useContext, useEffect, useState } from "react";
import CreateJoinRoom from "./CreateJoinRoom";
import BoxManager from "./BoxManager";
import { useSocket } from "common/hooks/useSocket";
import { RoomContext, defaultValues } from "App/context";
import { useTranslation } from "react-i18next";
import NavBar from "pages/Menu/Navbar";
import LanguageSelector from "./LanguageSelector";
import { Link, Route, Routes } from "react-router-dom";
import { getPlayer } from "service/player";
import { useErrorNotification } from "common/components/Notification";
import UnauthorizedDialog from "./UnauthorizedDialog";
import PlayersBox from "./PlayersBox";
import Balancing from "./Balancing";

const Main = () => {
  const { t } = useTranslation();
  const { konami } = useContext(RoomContext);

  return (
    <Stack flexGrow={0.5} justifyContent="center">
      <CreateJoinRoom />
      <LanguageSelector />
      <Stack position="absolute" bottom="0" right="1rem">
        <Typography size="sm">
          {t("Créé par Quernel, Biajo et Aerhenyu")}
        </Typography>
      </Stack>
      {konami && (
        <Stack
          position="absolute"
          width="100%"
          height="100%"
          left="0"
          top="0"
          sx={{
            "@keyframes ride": {
              "0%, 100%": {
                top: "0",
                left: "0",
              },
              "25%": {
                top: "0",
                left: "calc(100% - 5rem)",
              },
              "50%": {
                top: "calc(100% - 5rem)",
                left: "calc(100% - 5rem)",
              },
              "75%": {
                top: "calc(100% - 5rem)",
                left: "0",
              },
            },
            animation: "ride linear 8s infinite",
          }}
        >
          <CardMedia
            component="img"
            src={require("assets/images/twingo.png")}
            sx={{
              height: "5rem",
              width: "5rem",
              "@keyframes rotate": {
                "0%": {
                  transform: "rotate(0deg)",
                },
                "25%": {
                  transform: "rotate(90deg)",
                },
                "50%": {
                  transform: "rotate(180deg)",
                },
                "75%": {
                  transform: "rotate(270deg)",
                },
                "100%": {
                  transform: "rotate(360deg)",
                },
              },
              animation: "rotate linear 0.75s infinite",
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

const Box = () => {
  return <BoxManager />;
};

const Menu = () => {
  const socket = useSocket();
  const dispatchError = useErrorNotification();
  const { dispatch, konami } = useContext(RoomContext);
  const [open, setOpen] = useState<boolean>(false);

  const fetch = async () => {
    try {
      const player = await getPlayer();
      dispatch({ localPlayer: player });
    } catch (e) {
      if (e === "DiscordInsufficientAccessRightsException") setOpen(true);
      else dispatchError(e);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    dispatch({ ...defaultValues, konami });
    socket.connected && socket.disconnect();
    socket.connect();
  }, []);

  return (
    <Stack
      direction="column"
      alignItems="center"
      height="100%"
      gap={1}
      px={2}
      py={1}
    >
      <UnauthorizedDialog open={open} />
      <NavBar />
      <Divider flexItem orientation="horizontal" />
      <Routes>
        <Route path={"/"} element={<Main />} />
        <Route path={"/box"} element={<Box />} />
        <Route path={"/playersBox"} element={<PlayersBox />} />
        <Route path={"/balancing"} element={<Balancing />} />
      </Routes>
    </Stack>
  );
};

export default Menu;
