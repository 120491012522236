import { useSocket } from "common/hooks/useSocket";
import React, { useContext, useEffect } from "react";
import { DraftContext } from "../context";
import { Button } from "@mui/material";
import { isMyTurn, whoAMI } from "common/utils";
import { RoomContext } from "App/context";
import { CharacterBox } from "types/Player/type";
import { DraftCharacter } from "types/Draft/type";
import { CHARACTERS_LIST } from "types/Character";
import { useTranslation } from "react-i18next";
import SkewButton from "common/components/SkewButton";

const ActionButton = () => {
  const { t } = useTranslation();
  const socket = useSocket();
  const {
    currentStep,
    timerPlayer1,
    timerPlayer2,
    picksPlayer1,
    picksPlayer2,
    bansPlayer1,
    bansPlayer2,
    selectedPicksPlayer1,
    selectedPicksPlayer2,
    selectedBansPlayer1,
    selectedBansPlayer2,
    dispatch,
  } = useContext(DraftContext);
  const {
    player1,
    player2,
    draft,
    balancingBansPlayer1,
    jokerBansPlayer1,
    balancingBansPlayer2,
    jokerBansPlayer2,
  } = useContext(RoomContext);
  const role = whoAMI(socket, player1, player2);
  const currentSelect: DraftCharacter[] | undefined =
    role === "Player 1"
      ? selectedBansPlayer1 ?? selectedPicksPlayer1 ?? undefined
      : selectedBansPlayer2 ?? selectedPicksPlayer2 ?? undefined;
  const myTurn = currentSelect !== undefined;

  const onClickConfirm = () => {
    if (myTurn) {
      if (currentStep?.action === "Pick") {
        if (role === "Player 1") {
          socket.emit("send-chars-pick", [
            ...picksPlayer1,
            ...(selectedPicksPlayer1 ?? []),
          ]);
        } else {
          socket.emit("send-chars-pick", [
            ...picksPlayer2,
            ...(selectedPicksPlayer2 ?? []),
          ]);
        }
      } else {
        if (role === "Player 1") {
          if (currentStep?.player === "Both") {
            socket.emit("send-hide-ban", [...(selectedBansPlayer1 ?? [])]);
          } else {
            socket.emit("send-chars-ban", [
              ...bansPlayer1,
              ...(selectedBansPlayer1 ?? []),
            ]);
          }
        } else {
          if (currentStep?.player === "Both") {
            socket.emit("send-hide-ban", [...(selectedBansPlayer2 ?? [])]);
          } else {
            socket.emit("send-chars-ban", [
              ...bansPlayer2,
              ...(selectedBansPlayer2 ?? []),
            ]);
          }
        }
      }
    }
  };

  const selectRandom = (
    chars: DraftCharacter[],
    quantity: number,
    selected?: DraftCharacter[]
  ) => {
    let res = [...(selected ?? [])];
    while (res.length < quantity) {
      const random = Math.floor(Math.random() * chars.length);
      if (!res.some((ch) => ch.name === chars[random].name))
        res.push(chars[random]);
    }
    return res;
  };

  useEffect(() => {
    if (myTurn) {
      const applyFilters = (ch: DraftCharacter) =>
        !balancingBansPlayer1?.some((c) => c.name === ch.name) &&
        !balancingBansPlayer2?.some((c) => c.name === ch.name) &&
        !picksPlayer1?.some((c) => c.name === ch.name) &&
        !bansPlayer1?.some((c) => c.name === ch.name) &&
        !picksPlayer2?.some((c) => c.name === ch.name) &&
        !bansPlayer2?.some((c) => c.name === ch.name);
      let unionBox: DraftCharacter[] = [];
      CHARACTERS_LIST.filter((ch) => applyFilters(ch)).forEach((ch) => {
        const p1Char = player1?.box?.find((c) => c.name === ch.name);
        const p2Char = player2?.box?.find((c) => c.name === ch.name);
        if (p1Char !== undefined || p2Char !== undefined) {
          unionBox.push({
            name: ch.name,
            p1Constellation: p1Char?.constellation,
            p2Constellation: p2Char?.constellation,
          });
        }
      });
      if (role === "Player 1" && timerPlayer1 === draft.timer) {
        if (currentStep?.action === "Ban") {
          const chars = selectRandom(
            unionBox.filter((c) => c.p2Constellation !== undefined),
            currentStep.quantity,
            selectedBansPlayer1
          );
          if (currentStep?.player === "Both")
            socket.emit("send-hide-ban", chars);
          else socket.emit("send-chars-ban", [...bansPlayer1, ...chars]);
        } else if (currentStep?.action === "Pick") {
          const chars = selectRandom(
            unionBox.filter(
              (ch) =>
                ch.p1Constellation !== undefined &&
                !jokerBansPlayer2?.some((c) => c.name === ch.name)
            ),
            currentStep.quantity,
            selectedPicksPlayer1
          );
          socket.emit("send-chars-pick", [...picksPlayer1, ...chars]);
        }
        dispatch({ currentStep: undefined });
      }
      if (role === "Player 2" && timerPlayer2 === draft.timer) {
        if (currentStep?.action === "Ban") {
          const chars = selectRandom(
            unionBox.filter((c) => c.p1Constellation !== undefined),
            currentStep.quantity,
            selectedBansPlayer2
          );
          if (currentStep?.player === "Both")
            socket.emit("send-hide-ban", chars);
          else socket.emit("send-chars-ban", [...bansPlayer2, ...chars]);
        } else if (currentStep?.action === "Pick") {
          const chars = selectRandom(
            unionBox.filter(
              (ch) =>
                ch.p2Constellation !== undefined &&
                !jokerBansPlayer1?.some((c) => c.name === ch.name)
            ),
            currentStep.quantity,
            selectedPicksPlayer2
          );
          socket.emit("send-chars-pick", [...picksPlayer2, ...chars]);
        }
        dispatch({ currentStep: undefined });
      }
    }
  }, [currentStep, timerPlayer1, timerPlayer2]);

  return (
    <SkewButton
      sx={{ width: "fit-content", margin: "auto" }}
      disabled={
        !myTurn ||
        (myTurn && currentSelect.length < (currentStep?.quantity ?? 0))
      }
      onClick={() => onClickConfirm()}
    >
      {!myTurn
        ? t("En attente...")
        : currentStep?.action === "Ban"
        ? t("Bannir {{quantity}}/{{total}}", {
            quantity: currentSelect.length,
            total: currentStep.quantity,
          })
        : t("Verrouiller {{quantity}}/{{total}}", {
            quantity: currentSelect.length,
            total: currentStep?.quantity,
          })}
    </SkewButton>
  );
};

export default ActionButton;
