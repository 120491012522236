export type Character = {
  name: string;
  rarity: "4" | "5";
  element: ElementType | ElementType[];
  constellation?: number;
  dictionnary?: string[];
};

export enum ElementType {
  "Pyro",
  "Hydro",
  "Anemo",
  "Electro",
  "Dendro",
  "Cryo",
  "Geo",
}
