import { useSocket } from "common/hooks/useSocket";
import React, { useContext, useEffect, useState } from "react";
import { DraftContext } from "../context";
import {
  Button,
  CardMedia,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { RoomContext } from "App/context";
import BansList, { BannedCharacter } from "../BansList";
import PicksList from "./PicksList";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Avatar from "common/components/Avatar";
import { VS } from "assets/icons";
import GenshinButton from "common/components/GenshinButton";
import { Balance, Pause, PlayArrow } from "@mui/icons-material";
import BalancingBansButton from "./AdditionalBansButton";
import { DraftCharacter, TimerState } from "types/Draft/type";
import { formatTimer, whoAMI } from "common/utils";
import ConfirmLeave from "./ConfirmLeave";
import ConfirmReplay from "./ConfirmReplay";
import { Room } from "types/Room/type";
import PreparationTime from "./PreparationTime";

const PREPARATION_TIME = 7 * 60;
const valorantAudio = new Audio("./sounds/spike-valorant.mp3");
const kaamelottAudio = new Audio("./sounds/kaamelott.mp3");

const EndDraft = () => {
  const { t } = useTranslation();
  const socket = useSocket();
  const { picksPlayer1, picksPlayer2, bansPlayer1, bansPlayer2 } =
    useContext(DraftContext);
  const {
    admin,
    player1,
    player2,
    draft,
    balancingBansPlayer1,
    balancingBansPlayer2,
    jokerBansPlayer1,
    jokerBansPlayer2,
    dispatch,
  } = useContext(RoomContext);
  const theme = useTheme();
  const role = whoAMI(socket, player1, player2);
  const navigate = useNavigate();
  const [picksOrderP1, setPicksOrderP1] =
    useState<DraftCharacter[]>(picksPlayer1);
  const [picksOrderP2, setPicksOrderP2] =
    useState<DraftCharacter[]>(picksPlayer2);
  const [openPreparation, setOpenPreparation] = useState<boolean>(false);
  const [preparationTimer, setPreparationTimer] =
    useState<number>(PREPARATION_TIME);
  const [preparationTimerState, setPreparationTimerState] =
    useState<TimerState>("Pause");
  const [openLeave, setOpenLeave] = useState<boolean>(false);
  const [openReplay, setOpenReplay] = useState<boolean>(false);
  const [switchPlayers, setSwitchPlayers] = useState<boolean>(true);

  const subscribeEvents = () => {
    socket.on("picks-order-p1", (order: DraftCharacter[]) =>
      setPicksOrderP1(order)
    );
    socket.on("picks-order-p2", (order: DraftCharacter[]) =>
      setPicksOrderP2(order)
    );
    socket.on("timer-preparation", (time: number) => setPreparationTimer(time));
    socket.on("timer-state-preparation", (state: TimerState) =>
      setPreparationTimerState(state)
    );
    socket.on("replay", (room: Room, switchPlayers: boolean) => {
      if (switchPlayers) socket.emit("send-swap-roles");
      dispatch({
        ...room,
        player1: room.player1 ?? undefined,
        player2: room.player2 ?? undefined,
        balancingBansPlayer1: room.balancingBansPlayer1 ?? undefined,
        balancingBansPlayer2: room.balancingBansPlayer2 ?? undefined,
        jokerBansPlayer1: room.jokerBansPlayer1 ?? undefined,
        jokerBansPlayer2: room.jokerBansPlayer2 ?? undefined,
      });
      navigate("/waitingRoom");
    });
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (admin && preparationTimerState === "Play" && preparationTimer > 0) {
      intervalId = setInterval(
        () => socket.emit("send-timer-preparation", preparationTimer - 1),
        1000
      );
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [preparationTimer, preparationTimerState]);

  useEffect(() => {
    if (preparationTimer === 29) valorantAudio.play();
    else if (preparationTimer === 0) kaamelottAudio.play();
  }, [preparationTimer]);
  useEffect(() => {
    if (preparationTimerState === "Pause") {
      valorantAudio.pause();
      kaamelottAudio.pause();
    } else if (preparationTimer <= 29) valorantAudio.play();
  }, [preparationTimerState]);

  useEffect(() => {
    subscribeEvents();

    return () => {
      valorantAudio.pause();
      kaamelottAudio.pause();
      socket.removeListener("picks-order-p1");
      socket.removeListener("picks-order-p2");
      socket.removeListener("timer-preparation");
      socket.removeListener("timer-state-preparation");
      socket.removeListener("replay");
    };
  }, []);

  return (
    <Stack
      direction="column"
      gap={1}
      alignItems="start"
      height="100%"
      padding={1}
    >
      <PicksList
        picks={picksOrderP1}
        player="player1"
        onDrop={
          role === "Player 1"
            ? (order: DraftCharacter[]) =>
                socket.emit("send-picks-order-p1", order)
            : undefined
        }
      />
      <Stack direction="row" height="12rem" width="100%">
        <Stack
          alignItems="start"
          justifyContent="space-between"
          direction="row"
          width="100%"
        >
          <Stack direction="column">
            <BansList
              color={theme.palette.common.player1.banCard}
              banSteps={draft.steps.filter(
                (step) =>
                  step.action === "Ban" &&
                  (step.player === "Player 1" || step.player === "Both")
              )}
              bans={bansPlayer1}
            />
            {(balancingBansPlayer1 || jokerBansPlayer1) && (
              <BalancingBansButton
                balancingBans={balancingBansPlayer1}
                jokerBans={jokerBansPlayer1}
                orientation="right"
                color={theme.palette.common.player1.banCard}
              />
            )}
          </Stack>
          <Stack direction="row">
            <Typography
              textOverflow="ellipsis"
              textAlign="right"
              overflow="hidden"
              maxWidth="10rem"
              color={theme.palette.common.player1.text}
            >
              {player1?.pseudo}
            </Typography>
            <Avatar character={player1?.avatar} />
          </Stack>
        </Stack>
        <Stack
          height="100%"
          sx={{
            svg: {
              height: "100%",
              width: "100%",
            },
          }}
        >
          <VS />
        </Stack>
        <Stack
          alignItems="end"
          justifyContent="space-between"
          direction="row"
          width="100%"
        >
          <Stack direction="row" alignItems="end">
            <Avatar character={player2?.avatar} />
            <Typography
              textOverflow="ellipsis"
              overflow="hidden"
              maxWidth="10rem"
              color={theme.palette.common.player2.text}
            >
              {player2?.pseudo}
            </Typography>
          </Stack>
          <Stack direction="column">
            <Stack direction="row" justifyContent="end" gap={1}>
              {(balancingBansPlayer2 || jokerBansPlayer2) && (
                <BalancingBansButton
                  balancingBans={balancingBansPlayer2}
                  jokerBans={jokerBansPlayer2}
                  orientation="left"
                  color={theme.palette.common.player2.banCard}
                />
              )}
            </Stack>
            <BansList
              color={theme.palette.common.player2.banCard}
              banSteps={draft.steps.filter(
                (step) =>
                  step.action === "Ban" &&
                  (step.player === "Player 2" || step.player === "Both")
              )}
              bans={bansPlayer2}
            />
          </Stack>
        </Stack>
      </Stack>
      <PicksList
        picks={picksOrderP2}
        player="player2"
        onDrop={
          role === "Player 2"
            ? (order: DraftCharacter[]) =>
                socket.emit("send-picks-order-p2", order)
            : undefined
        }
      />
      <Stack justifyContent="space-between" direction="row" width="100%">
        <Stack direction="row">
          <PreparationTime
            open={openPreparation}
            setOpen={setOpenPreparation}
            time={formatTimer(preparationTimer)}
          />
          <GenshinButton
            variant="contained"
            onClick={() => setOpenPreparation(true)}
          >
            {t("Préparation : {{time}}", {
              time: formatTimer(preparationTimer),
            })}
          </GenshinButton>
          {preparationTimerState === "Pause" ? (
            <IconButton
              disabled={!admin}
              onClick={() =>
                socket.emit("send-timer-state-preparation", "Play")
              }
            >
              <PlayArrow />
            </IconButton>
          ) : (
            <IconButton
              disabled={!admin}
              onClick={() =>
                socket.emit("send-timer-state-preparation", "Pause")
              }
            >
              <Pause />
            </IconButton>
          )}
        </Stack>
        <Stack direction="row" gap={2}>
          <GenshinButton
            variant="contained"
            onClick={() => setOpenReplay(true)}
          >
            {t("Rejouer")}
          </GenshinButton>
          <GenshinButton
            variant="contained"
            onClick={() => {
              if (admin) {
                setOpenLeave(true);
              } else {
                socket.emit("send-leave-room");
                navigate("/");
              }
            }}
          >
            {t("Quitter")}
          </GenshinButton>
        </Stack>
      </Stack>
      <ConfirmLeave open={openLeave} setOpen={setOpenLeave} />
      <ConfirmReplay
        open={openReplay}
        setOpen={setOpenReplay}
        switchPlayers={switchPlayers}
        setSwitchPlayers={setSwitchPlayers}
      />
    </Stack>
  );
};

export default EndDraft;
