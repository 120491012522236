import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Character } from "types/Character/type";
import React from "react";
import { API_URL } from "service/root/rootApi";
import { Player } from "service/player/type";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import Typography from "common/components/Typography";

type RowCharacterProps = {
  character: Character;
  constellationPlayer1?: number;
  constellationPlayer2?: number;
};

const RowCharacter = (props: RowCharacterProps) => {
  const { character, constellationPlayer1, constellationPlayer2 } = props;
  const { t } = useTranslation();

  return (
    <Stack direction="row" alignItems="center" height="100%" padding={1}>
      <Stack flex={1} direction="row" alignItems="center" gap={1}>
        <CardMedia
          component="img"
          sx={{
            width: "4rem",
            borderRadius: "3rem",
            border: "1px solid gray",
          }}
          src={`${API_URL}assets/characters/${character.name}/thumb.png`}
          title={character.name}
        />
        <Typography size="sm">{character.name}</Typography>
      </Stack>
      <Stack
        flex={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height="100%"
      >
        <Divider orientation="vertical" flexItem />
        <Box
          width="2.75rem"
          padding={0.4}
          visibility={constellationPlayer1 !== undefined ? "visible" : "hidden"}
          borderRadius="2rem"
          textAlign="center"
          fontSize="1.5rem"
          sx={{
            border: ({ palette }) =>
              `1px solid ${palette.common.player1.borderConstel}`,
          }}
        >
          {constellationPlayer1 ?? 0}
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          width="2.75rem"
          padding={0.4}
          visibility={constellationPlayer2 !== undefined ? "visible" : "hidden"}
          borderRadius="2rem"
          textAlign="center"
          fontSize="1.5rem"
          sx={{
            border: ({ palette }) =>
              `1px solid ${palette.common.player2.borderConstel}`,
          }}
        >
          {constellationPlayer2 ?? 0}
        </Box>
        <Divider orientation="vertical" flexItem />
      </Stack>
    </Stack>
  );
};

export default RowCharacter;
