import { useSocket } from "common/hooks/useSocket";
import React, { useContext, useEffect } from "react";
import { DraftContext } from "../context";
import { Button, CardMedia, Paper, Stack, Typography } from "@mui/material";
import { CHARACTERS_LIST } from "types/Character";
import FilteringBar from "./FilteringBar";
import { RoomContext } from "App/context";
import { Character } from "types/Character/type";
import { isMyTurn, whoAMI } from "common/utils";
import { CharacterBox } from "types/Player/type";
import { DraftCharacter } from "types/Draft/type";
import Constellation from "common/components/Constellation";
import { FilterContext, FilterProvider } from "./context";
import { BalancingBansContext } from "pages/WaitingRoom/BalancingBans/context";
import { JokerBansContext } from "pages/WaitingRoom/JokerBans/context";
import { API_URL } from "service/root/rootApi";

const BoxList = () => {
  const socket = useSocket();
  const {
    currentStep,
    selectedPicksPlayer1,
    selectedPicksPlayer2,
    selectedBansPlayer1,
    selectedBansPlayer2,
    picksPlayer1,
    picksPlayer2,
    bansPlayer1,
    bansPlayer2,
  } = useContext(DraftContext);
  const {
    element,
    rarity,
    player,
    search,
    sortConstelDesc,
    dispatch: dispatchFilter,
  } = useContext(FilterContext);
  const {
    player1,
    player2,
    draft,
    balancingBansPlayer1,
    jokerBansPlayer1,
    balancingBansPlayer2,
    jokerBansPlayer2,
  } = useContext(RoomContext);
  const {
    selectingBans: selectingBalancingBans,
    selectedBansPlayer1: selectedBalancingBansPlayer1,
    selectedBansPlayer2: selectedBalancingBansPlayer2,
  } = useContext(BalancingBansContext);
  const {
    selectingBans: selectingJokerBans,
    selectedBansPlayer1: selectedJokerBansPlayer1,
    selectedBansPlayer2: selectedJokerBansPlayer2,
  } = useContext(JokerBansContext);

  const role = whoAMI(socket, player1, player2);
  const currentSelect: DraftCharacter[] | undefined =
    role === "Player 1"
      ? selectedBansPlayer1 ??
        selectedPicksPlayer1 ??
        selectedBalancingBansPlayer1 ??
        selectedJokerBansPlayer1 ??
        undefined
      : role === "Player 2"
      ? selectedBansPlayer2 ??
        selectedPicksPlayer2 ??
        selectedBalancingBansPlayer2 ??
        selectedJokerBansPlayer2 ??
        undefined
      : undefined;
  const myTurn = currentSelect !== undefined;

  const getCharactersList = (): DraftCharacter[] => {
    let res: DraftCharacter[] = [];
    CHARACTERS_LIST.filter((ch) => {
      /*
        Application des bans d'équilibrages
      */
      if (
        balancingBansPlayer1?.some((c) => ch.name === c.name) ||
        balancingBansPlayer2?.some((c) => ch.name === c.name)
      )
        return false;

      /*
        Application des filtres de la barre
      */
      if (search !== "")
        return ch.name.toLowerCase().includes(search.toLowerCase());
      if (
        (element !== null
          ? Array.isArray(ch.element)
            ? ch.element.includes(element)
            : ch.element === element
          : true) &&
        (rarity !== null ? ch.rarity === rarity : true)
      ) {
        return true;
      }
      return false;
    }).forEach((ch) => {
      const p1Char = player1?.box?.find((c) => c.name === ch.name);
      const p2Char = player2?.box?.find((c) => c.name === ch.name);
      if (p1Char !== undefined || p2Char !== undefined) {
        res.push({
          name: ch.name,
          p1Constellation: p1Char?.constellation,
          p2Constellation: p2Char?.constellation,
        });
      }
    });
    return res;
  };

  const isCharacterSelected = (character: DraftCharacter) => {
    if (myTurn) {
      return currentSelect?.some((ch) => ch.name === character.name);
    }
    return false;
  };

  const onClickCharacter = (character: DraftCharacter) => {
    if (myTurn) {
      const index = currentSelect.findIndex((ch) => ch.name === character.name);
      if (index !== -1) {
        currentSelect.splice(index, 1);
      } else {
        if (currentSelect.length === currentStep?.quantity) {
          currentSelect.pop();
        }
        currentSelect.push(character);
      }
      socket.emit(
        currentStep
          ? currentStep.action === "Pick"
            ? "send-selected-chars-pick"
            : "send-selected-chars-ban"
          : selectingBalancingBans
          ? "send-selected-balanced-ban"
          : selectingJokerBans
          ? "send-selected-joker-ban"
          : "",
        currentSelect
      );
    }
  };

  const applyFilters = (character: DraftCharacter) => {
    const p1HasCharacter = player1?.box?.some(
      (ch) => ch.name === character.name
    );
    const p1BannedCharacter = bansPlayer1.some(
      (ch) => ch.name === character.name
    );
    const p1BannedJocker = jokerBansPlayer1?.some(
      (ch) => ch.name === character.name
    );
    const p1PickedCharacter = picksPlayer1.some(
      (ch) => ch.name === character.name
    );
    const p2HasCharacter = player2?.box?.some(
      (ch) => ch.name === character.name
    );
    const p2BannedCharacter = bansPlayer2.some(
      (ch) => ch.name === character.name
    );
    const p2BannedJocker = jokerBansPlayer2?.some(
      (ch) => ch.name === character.name
    );
    const p2PickedCharacter = picksPlayer2.some(
      (ch) => ch.name === character.name
    );

    /*
      Application des bans joker
    */
    if (
      myTurn &&
      currentStep?.action === "Pick" &&
      ((role === "Player 1" && p2BannedJocker && p1HasCharacter) ||
        (role === "Player 2" && p1BannedJocker && p2HasCharacter))
    ) {
      return false;
    }
    if (
      (p1BannedJocker && character.p1Constellation === undefined) ||
      (p2PickedCharacter && character.p2Constellation === undefined)
    )
      return false;

    /*
      Règles de base, on cache :
        - si l'adversaire nous a ban un perso (sauf si on est a une étape de Ban en même temps)
        - si on l'a nous meme pick
    */
    if (
      (role === "Player 1" && p1HasCharacter && p2BannedCharacter) ||
      (role === "Player 2" && p2HasCharacter && p1BannedCharacter) ||
      (role === "Player 1" && p1PickedCharacter) ||
      (role === "Player 2" && p2PickedCharacter)
    ) {
      return false;
    }

    /*
      Règles spéciales si on est en ban et/ou picks partagés
    */
    if (draft.banShared) {
      if (p1BannedCharacter || p2BannedCharacter) {
        return false;
      }
    }
    if (draft.pickShared) {
      if (p1PickedCharacter || p2PickedCharacter) {
        return false;
      }
    }

    /*
      Si le filtre de joueur est appliqué
    */
    if (player !== null) {
      if (player === "Player 1") return character.p1Constellation !== undefined;
      else return character.p2Constellation !== undefined;
    }

    return true;
  };

  const applySorting = (a: DraftCharacter, b: DraftCharacter) => {
    const getCount = (constel?: number) => {
      if (constel === undefined) return -1;
      if (constel > 0) {
        return constel ** constel;
      }
      return 0;
    };
    if (sortConstelDesc) {
      if (player === "Player 1")
        return (b.p1Constellation ?? 0) - (a.p1Constellation ?? 0);
      if (player === "Player 2")
        return (b.p2Constellation ?? 0) - (a.p2Constellation ?? 0);
      const aCount = getCount(a.p1Constellation) + getCount(a.p2Constellation);
      const bCount = getCount(b.p1Constellation) + getCount(b.p2Constellation);
      return bCount - aCount;
    }
    return 0;
  };

  return (
    <>
      <FilteringBar />
      <Paper
        sx={{
          overflow: "auto",
          height: "100%",
          width: "100%",
          flexGrow: "1",
          bgcolor: "transparent",
        }}
      >
        <Stack
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(11rem, 1fr))",
          }}
        >
          {getCharactersList()
            .filter((character) => applyFilters(character))
            .sort((a, b) => applySorting(a, b))
            .map((character, index) => (
              <Button
                key={index}
                onClick={() => onClickCharacter(character)}
                disabled={
                  myTurn &&
                  currentStep?.action === "Pick" &&
                  ((role === "Player 1" &&
                    character.p1Constellation === undefined) ||
                    (role === "Player 2" &&
                      character.p2Constellation === undefined))
                }
                sx={{
                  height: "9rem",
                  filter: `grayscale(${
                    myTurn &&
                    currentStep?.action === "Pick" &&
                    ((role === "Player 1" &&
                      character.p1Constellation === undefined) ||
                      (role === "Player 2" &&
                        character.p2Constellation === undefined))
                      ? 1
                      : 0
                  })`,
                  backgroundColor: ({ palette }) =>
                    isCharacterSelected(character)
                      ? currentStep?.action === "Pick"
                        ? palette.common.player1.banCard
                        : palette.common.player2.banCard
                      : "transparent",
                  ":hover": {
                    backgroundColor: ({ palette }) =>
                      isCharacterSelected(character)
                        ? currentStep?.action === "Pick"
                          ? palette.common.player1.banCard
                          : palette.common.player2.banCard
                        : "transparent",
                  },
                }}
              >
                <Stack
                  sx={{
                    height: "100%",
                    width: "100%",
                    position: "relative",
                    border: ({ palette }) =>
                      `.5px solid ${palette.common.grey.g474a53}`,
                    overflow: "hidden",
                    borderRadius: "0.5rem",
                  }}
                >
                  {character.p1Constellation !== undefined &&
                    !jokerBansPlayer2?.some(
                      (c) => c.name === character.name
                    ) && (
                      <Constellation player="player1">
                        {character.p1Constellation}
                      </Constellation>
                    )}
                  {character.p2Constellation !== undefined &&
                    !jokerBansPlayer1?.some(
                      (c) => c.name === character.name
                    ) && (
                      <Constellation player="player2">
                        {character.p2Constellation}
                      </Constellation>
                    )}
                  <CardMedia
                    component="img"
                    src={`${API_URL}assets/characters/${character.name}/profile.png`}
                    sx={{
                      objectPosition: "75% 25%",
                      bgcolor: ({ palette }) => `${palette.background.paper}99`,
                      transition: "transform .1s",
                      ":hover": {
                        transform: "scale(1.15)",
                      },
                    }}
                  />
                </Stack>
              </Button>
            ))}
        </Stack>
      </Paper>
    </>
  );
};

const BoxListWithContext = () => {
  return (
    <FilterProvider>
      <BoxList />
    </FilterProvider>
  );
};

export default BoxListWithContext;
