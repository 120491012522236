import axios, {
  AxiosError,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";
import { JWT_TOKEN } from "common/constants";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export const API_URL =
  process.env.REACT_APP_BACKEND_URL ?? "https://api.devaspig.online/";

const apiConfig: AxiosRequestConfig = {
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
  },
};
const loginURL =
  process.env.REACT_APP_LOGIN_URL ??
  process.env.REACT_APP_HOME_URL ??
  "www.google.com";

const rejectInterceptor = (error: AxiosError<{ message: string }>) => {
  const message = error.response?.data.message;
  switch (message) {
    case "JWT not found":
    case "Invalid JWT Token":
    case "Expired JWT Token":
      window.location.href = loginURL;
      break;
    default:
      break;
  }
  return Promise.reject(message);
};

const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  const cookies = new Cookies();
  const jwtToken = cookies.get(JWT_TOKEN);

  return {
    ...config,
    headers: {
      ...config.headers,
      ...(jwtToken ? { Authorization: jwtToken } : {}),
    },
  } as unknown as InternalAxiosRequestConfig;
};

const instance = axios.create(apiConfig);
instance.interceptors.response.use(undefined, rejectInterceptor);
instance.interceptors.request.use(requestInterceptor);

export default instance;
