import { IconButton, Stack, TextField, Tooltip, styled } from "@mui/material";
import Typography from "common/components/Typography";
import { CHARACTERS_LIST } from "types/Character";
import { CharacterBox, Player } from "types/Player/type";
import React, { useContext, useState } from "react";
import { useDebouncedState } from "common/hooks/useDebounce";
import { useTranslation } from "react-i18next";
import GenshinButton from "common/components/GenshinButton";
import { DividerLeft, DividerRight } from "assets/icons";
import { NotificationContext } from "common/components/Notification";
import { useErrorNotification } from "common/components/Notification";
import { RoomContext } from "App/context";
import { Box } from "service/player/type";
import { WithGrow } from "common/components/WithGrow";

type BalancingProps = {};

const Balancing = (props: BalancingProps) => {
  const { dispatch, localPlayer } = useContext(RoomContext);
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [debouncedValue, setDebouncedValue] = useDebouncedState<string>(
    search,
    (value) => setSearch(value)
  );

  return (
    <WithGrow
      direction="column"
      sx={{
        gap: 2,
      }}
      fixedSx={{
        width: "100%",
        alignItems: "center",
      }}
      fixed={
        <React.Fragment>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={{
              svg: { width: "15rem" },
            }}
          >
            <DividerRight />
            <Typography>{t("Équilibrage")}</Typography>
            <DividerLeft />
          </Stack>
        </React.Fragment>
      }
    ></WithGrow>
  );
};

export default Balancing;
