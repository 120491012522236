import { Card, CardMedia, IconButton, Skeleton, Stack } from "@mui/material";
import React from "react";
import { API_URL } from "service/root/rootApi";
import { Player } from "service/player/type";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import Typography from "common/components/Typography";

type ComparePlayerCardProps = {
  player?: Player;
  onDelete: () => void;
  color: "player1" | "player2";
};

const ComparePlayerCard = (props: ComparePlayerCardProps) => {
  const { player, onDelete, color } = props;
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        height: 170,
        width: 160,
        bgcolor: ({ palette }) => palette.common[color].paper,
      }}
    >
      <Stack
        direction="column"
        gap={0.5}
        padding={1}
        justifyContent={player !== undefined ? "space-between" : "center"}
        alignItems="center"
        height="100%"
      >
        {player === undefined ? (
          <>
            <Typography size="sm" textAlign="center">
              {t("Sélectionne un joueur")}
            </Typography>
            <Skeleton
              variant="circular"
              width={60}
              height={60}
              animation={false}
            />
          </>
        ) : (
          <>
            <Stack alignItems="end" width="100%">
              <IconButton onClick={onDelete} sx={{ padding: 0 }}>
                <Close />
              </IconButton>
            </Stack>
            <Typography
              textAlign="center"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              width="8rem"
              size="sm"
            >
              {player.pseudo}
            </Typography>
            <CardMedia
              component="img"
              sx={{
                width: "4.5rem",
              }}
              src={`${API_URL}assets/characters/${player.avatar}/avatar.png`}
              title={player.pseudo}
            />
            {player.boxes?.at(0)?.score}
          </>
        )}
      </Stack>
    </Card>
  );
};

export default ComparePlayerCard;
